<template>
  <div class="me">
<!--    <MyHeader></MyHeader>-->
    <new-my-header v-if="$route.path !='/'"></new-my-header>
    <keep-alive include="goods">
      <router-view></router-view>
    </keep-alive>
    <MyFooter></MyFooter>
    <div v-if="$route.path !='/'" class="right_nav">
      <ul>
        <li class="contact" @click="goUser('5')">
          <img src="../assets/imgs/tousu.png" />
          我要投诉
        </li>
        <li class="qrcode" @click="goUser('2-2')">
          <img src="../assets/imgs/collect.png" />
          我的收藏
        </li>
<!--        <li class="qrcode">-->
<!--          <a target="_blank" href="https://cschat.antcloud.com.cn/index.htm?tntInstId=osv_cVM6&scene=SCE01228211">-->
<!--            <img src="../assets/imgs/kefu.png" />-->
<!--            联系客服-->
<!--          </a>-->
<!--        </li>-->
        <li class="qrcode">
          <img src="../assets/imgs/qrcode.png" />
          微信
          <br />
          小程序
          <span><img src="../assets/imgs/wxqrcode.png" /></span>
        </li>
        <li class="qrcode" @click="backtop">
          <img src="../assets/imgs/gotop.png" />
          返回顶部
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
    console.log(this.$route,'$route')
  },
  computed: {},
  watch: {},
  methods: {
    // 返回顶部
    backtop() {
      const timer = setInterval(function() {
        const top = document.documentElement.scrollTop || document.body.scrollTop
        const speed = Math.floor(-top / 5)
        document.documentElement.scrollTop = document.body.scrollTop = top + speed
        if (top === 0) {
          clearInterval(timer)
        }
      }, 20)
    },
    goUser(key){
      this.$router.push({
        path: '/User',
        query:{key}
      })
    }
  }
}
</script>

<style scoped>
.me{
  background: #f5f5f5;
}
</style>
